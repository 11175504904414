import * as React from "react"
import { graphql } from "gatsby"
import { Layout, Header } from '../components'
import { TypeNews } from "../containers/Main"

class Article extends React.Component {
  render() {
    const {
      data: {
        prismicNews: {
          uid,
          data: {
            title,
            description,
            image,
          }
        }
      }
    } = this.props;

    return (
      <Layout
        context={{
          title: title.text,
          description: description.text,
          slug: `/actualites/${uid}/`,
        }}
      >
        <TypeNews>
          <Header location={this.props.location} />
          <div style={{ paddingTop: '3.2rem' }}>
            <h1>{title.text}</h1>
            <p dangerouslySetInnerHTML={{ __html: description.html }}></p>
            <img src={image.localFile.publicURL} alt={title.text} />
          </div>
        </TypeNews>
      </Layout>
    )
  }
}

export default Article;
export const articleQuery = graphql`
  query currentArticle($slug: String!) {
    prismicNews(uid: { eq: $slug }) {
      uid
      data {
        archive
        createdat(formatString: "DD/MM/YYYY")
        publicdate
        description {
          html
        }
        status
        title {
          text
        }
        image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`